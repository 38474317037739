import { ReportService } from './../../services/report-service';
import { ImpregnationService } from './../../services/impregnation-service';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { CuttingStrapTypeService } from 'services/cutting-strap-type-service';
import { NetPreparationService } from 'services/net-preparation-service';
import { NetService } from 'services/net-service';
import { PackagingMethodService } from 'services/packaging-method-service';
import { PackagingTypeService } from 'services/packaging-type-service';
import { PreparationRopeService } from 'services/preparation-rope-service';
import { StrapTypeService } from 'services/strap-type-service';
import { ImpregnationTypeService } from 'services/impregnation-type-service';
import { ToastService } from 'services/toast-service';
import { Utility } from '../../utility';
import { ValidationController } from 'aurelia-validation';
import { Prompt } from 'elements/prompt';
import { AsyncErrorHandler } from 'lib/ui';
import { MaterialImpregnationUptakeFactor } from 'models';

@autoinject
export class SimpleOfferTabImpregnation {
  private netPreparationId: number;
  private impregnationId: number;
  private netId: number;

  private originalObject: Models.NetPreparation;
  private originalImpregnationObject: Models.Impregnation;

  private net: Models.Net;
  protected formIsValid: boolean = true;
  protected locked: boolean = false;

  private impregnation: Models.Impregnation;

  private netpreparation: Models.NetPreparation;
  protected preparationropes: Array<Models.PreparationRope>;
  private nextTabIndex: number = null;
  protected packagingmethods: Array<Models.PackagingMethod>;
  private preparationRopeNewFormVisible: boolean = false;
  private preparationRopeEditFormVisible: number = null;

  public noPreparationDataExists: boolean = false;

  private downloadOfferSpecificationProgress: boolean = false;
  private downloadOfferConceptDrawingProgress: boolean = false;

  private changeTabSubscription: Subscription;
  private subscription1: Subscription;
  private subscription2: Subscription;
  private subscription3: Subscription;

  constructor(
    protected cuttingStrapTypeService: CuttingStrapTypeService,
    protected packagingTypeService: PackagingTypeService,
    protected strapTypeService: StrapTypeService,
    private dialogService: DialogService,
    private eventAggregator: EventAggregator,
    private reportService: ReportService,
    private impregnationTypeService: ImpregnationTypeService,
    private impregnationService: ImpregnationService,
    private netPreparationService: NetPreparationService,
    private netService: NetService,
    private packagingMethodService: PackagingMethodService,
    private preparationRopeService: PreparationRopeService,
    private toastService: ToastService,
    private utility: Utility,
    private validationController: ValidationController
  ) {}

  protected async activate(params: { NetId: number; Id: number }) {
    this.netId = params.NetId || params.Id;

    await this.getNet(this.netId);
    await this.getPackagingMethods();
    await this.getImpregnationUptakeFactors(this.impregnation.ImpregnationTypeId);

    // Update NetPreparation when creating new preparationRopes
    this.subscription1 = this.eventAggregator.subscribe('preparationRopeListReset', () => {
      this.preparationRopeEditFormVisible = null;
      void this.getPreparationRopes();
      this.eventAggregator.publish('offer-refresh', 'price');
    });

    this.subscription2 = this.eventAggregator.subscribe('preparationRopeFormEditClose', () => {
      void this.getPreparationRopes();
    });

    this.eventAggregator.subscribe('preparationRopeFormNewClose', () => {
      this.preparationRopeNewFormVisible = false;
    });

    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.changeTabSubscription = this.eventAggregator.subscribe('changeTab', (tabIndex: number) => {
      this.nextTabIndex = tabIndex;
    });

    // refresh if locked / unlocked
    this.subscription3 = this.eventAggregator.subscribe('netLocked', () => {
      void this.getNet(this.netId);
    });
  }

  protected detached() {
    if (this.changeTabSubscription) {
      this.changeTabSubscription.dispose();
    }
  }

  protected deactivate() {
    if (this.changeTabSubscription) {
      this.changeTabSubscription.dispose();
    }

    if (this.subscription1) {
      this.subscription1.dispose();
    }
    if (this.subscription2) {
      this.subscription2.dispose();
    }
    if (this.subscription3) {
      this.subscription3.dispose();
    }
  }

  @AsyncErrorHandler
  protected async deletePreparationRope(id: number) {
    await this.preparationRopeService.delete(id);
    await this.getPreparationRopes();

    this.toastService.showSuccess('preparationrope.deleted');
  }

  @AsyncErrorHandler
  private async updateNetPreparation() {
    this.netpreparation.PreparationRopes = null;
    this.toastService.showSuccess('netpreparation.updated');
    this.updateOriginalObject();

    await this.netPreparationService.put(this.netpreparation, this.netpreparation.Id);
    await this.getNetPreparation(this.netPreparationId);
  }

  protected cancel() {
    this.netpreparation = this.originalObject;
  }

  @AsyncErrorHandler
  private async getNet(id: number) {
    const res = await this.netService.get(id);

    this.net = res;
    this.netPreparationId = res.NetPreparationId;
    this.impregnationId = res.ImpregnationId;
    this.locked = res.Locked ? res.Locked : false;

    if (this.net.NetPreparationId) {
      this.noPreparationDataExists = false;
      await this.getNetPreparation(this.netPreparationId);
    } else {
      this.noPreparationDataExists = true;
    }

    if (this.net.ImpregnationId) {
      await this.getImpregnation(this.impregnationId);
    }
  }

  @AsyncErrorHandler
  private async getNetPreparation(id: number) {
    const res = await this.netPreparationService.get(id);
    this.netpreparation = res;
    this.updateOriginalObject();
    await this.getPreparationRopes();
  }

  @AsyncErrorHandler
  private async getImpregnation(id: number) {
    const res = await this.impregnationService.get(id);
    this.impregnation = res;
    this.updateOriginalImpregnationObject();
  }

  @AsyncErrorHandler
  private async getPackagingMethods() {
    const res = await this.packagingMethodService.getAllCached();
    this.packagingmethods = res.filter((x) => !x.IsDeleted);
  }

  @AsyncErrorHandler
  private async getPreparationRopes() {
    const res = await this.preparationRopeService.getAll(
      '?$filter=NetPreparationId eq ' +
        this.netpreparation.Id +
        '&$expand=RopeType,PreparationRopeType,RopeDimension,PreparationRopePlacement,PreparationRopeKnot1,PreparationRopeKnot2,PreparationRopeKnotPlacement1,PreparationRopeKnotPlacement2'
    );
    this.preparationropes = res;
  }

  protected async validateForm() {
    const { valid } = await this.validationController.validate();
    this.formIsValid = valid;
    return valid;
  }

  private updateOriginalObject() {
    if (!this.netpreparation) {
      return;
    }
    this.originalObject = JSON.parse(JSON.stringify(this.netpreparation));
  }

  private updateOriginalImpregnationObject() {
    if (!this.impregnation) {
      return;
    }
    this.originalImpregnationObject = JSON.parse(JSON.stringify(this.impregnation));
  }

  @AsyncErrorHandler
  private async save() {
    this.netpreparation.PreparationRopes = null;

    await Promise.all([
      this.netPreparationService.put(this.netpreparation, this.netpreparation.Id),
      this.impregnationService.put(this.impregnation, this.impregnationId),
    ]);
    this.toastService.showSuccess('netpreparation.updated');

    await this.getNetPreparation(this.netPreparationId);
    await this.getImpregnation(this.impregnationId);

    this.updateOriginalObject();
    this.updateOriginalImpregnationObject();
  }

  private changeTab(direction: string = 'next') {
    if (direction === 'prev') {
      this.eventAggregator.publish('triggerPrevTab', true);
    } else {
      this.eventAggregator.publish('triggerNextTab', true);
    }
  }

  protected async saveAndChangeTab(direction: string = 'next') {
    const isValid = await this.validateForm();

    if (!isValid) {
      this.toastService.showWarning('general.checkValidationErrors');
      return;
    }

    if (
      !this.utility.areEqual(this.impregnation, this.originalImpregnationObject, true) ||
      !this.utility.areEqual(this.netpreparation, this.originalObject, true)
    ) {
      await this.save();
    }
    this.changeTab(direction);
  }

  protected async saveAndGotoNextTab() {
    // does it validate ?
    const isValid = await this.validateForm();

    if (!isValid) {
      this.toastService.showWarning('general.checkValidationErrors');
      return;
    }

    // check if different
    if (!this.utility.areEqual(this.netpreparation, this.originalObject, true)) {
      await this.save();
    }
    this.eventAggregator.publish('triggerNextTab', true);
  }

  protected canDeactivate() {
    if (
      !this.net.Locked &&
      ((this.originalObject && !this.utility.areEqual(this.netpreparation, this.originalObject)) ||
        this.preparationRopeNewFormVisible ||
        this.preparationRopeEditFormVisible > 0)
    ) {
      if (this.originalObject && !this.utility.areEqual(this.netpreparation, this.originalObject)) {
        // tslint:disable-next-line:no-console
        return this.dialogService
          .open({
            viewModel: Prompt,
            model: { message: 'dialog.unsavedChangesText' },
          })
          .whenClosed(async (response) => {
            if (response.wasCancelled) {
              return false;
            } else {
              const result = response.output;
              if (result === 'save') {
                // save the nettingtype and let that function handle the rest of the logic
                await this.updateNetPreparation();
                return false;
              } else {
                return true;
              }
            }
          });
      }
      if (this.preparationRopeNewFormVisible || this.preparationRopeEditFormVisible > 0) {
        return this.dialogService
          .open({
            viewModel: Prompt,
            model: {
              header: 'dialog.subFormOpenHeading',
              message: 'dialog.subFormOpenMessage',
              actions: {
                delete: { enabled: false },
                save: { enabled: false },
                cancel: { enabled: true, t: 'dialog.cancel' },
                dontsave: { enabled: false },
                continue: { enabled: true, t: 'dialog.continue' },
              },
            },
          })
          .whenClosed((response) => {
            if (response.wasCancelled) {
              return false;
            } else {
              const result = response.output;
              if (result === 'continue') {
                this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
                return true;
              }
            }
          });
      }
    } else {
      this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
      return true;
    }
  }

  @AsyncErrorHandler
  protected async downloadOfferSpecification() {
    try {
      if (this.downloadOfferSpecificationProgress) {
        return;
      }
      this.downloadOfferSpecificationProgress = true;
      await this.reportService.getSpecificationReport(this.netId);
      this.toastService.showSuccess('document.downloaded');
    } catch (error) {
      this.downloadOfferSpecificationProgress = false;
    } finally {
      this.downloadOfferSpecificationProgress = false;
    }
  }

  protected impregnationTypeUptakeFactorsForNet: MaterialImpregnationUptakeFactor[] = [];

  @AsyncErrorHandler
  protected async getImpregnationUptakeFactors(impregnationTypeId: number) {
    if (!impregnationTypeId) return;
    const { data } = await this.impregnationTypeService.getMaterialUptakeFactorsForNet({
      impregnationTypeId,
      netId: this.netId,
    });

    this.impregnationTypeUptakeFactorsForNet = data;
  }

  @AsyncErrorHandler
  protected async setImpregnationTypeId(typeId: number) {
    this.impregnation.ImpregnationTypeId = typeId;
    await this.getImpregnationUptakeFactors(typeId);
  }

  @AsyncErrorHandler
  protected async downloadOfferConceptDrawing() {
    try {
      if (this.downloadOfferConceptDrawingProgress) {
        return;
      }
      this.downloadOfferConceptDrawingProgress = true;
      await this.reportService.getDrawingReport(this.netId);
      this.toastService.showSuccess('document.downloaded');
    } catch (error) {
      this.downloadOfferConceptDrawingProgress = false;
      throw error;
    } finally {
      this.downloadOfferConceptDrawingProgress = false;
    }
  }
}
